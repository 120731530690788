import { TViewMode } from 'models/Content';
import LibraryList from './components/LibraryList';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LibraryGallery } from './components/LibraryGallery';
import './LibraryPage.scss';
import { getLibraries } from 'apis/Library/Library';
import { Library, LibraryQuery } from 'types';
import { useQuery } from 'react-query';
import { mappingLibrary } from 'helpers/libraries';
import { useSearchParams } from 'react-router-dom';
import { LibraryViewMode } from './components/LibraryViewsMode';
import { TNavBarValue } from 'types/header';
import { LibraryNav } from './components/LibraryNav';
import { PageHeader } from 'components/PageHeader';
import { useAppStore } from 'hooks/useAppStore';
import { useDebounce } from 'react-use';
import { ErrorEmptyBlock } from 'components/ErrorEmptyBlock';
import { Loading, MichiAssistantBtn } from 'components';
import useModal from 'hooks/useModal';

export const LibraryPage = () => {
  const [viewMode, setViewMode] = useState<TViewMode>('gallery');
  const [searchParams, setSearchParams] = useSearchParams({ orderBy: 'popularity' });
  const [keyword, setKeyword] = useState<string>('');
  const { closeModal, openModal } = useModal();

  const { uiStore } = useAppStore();

  const libraryWrapperRef = useRef(null);

  const searchTerm = useMemo(() => {
    const queries = Object.fromEntries(searchParams.entries()) as LibraryQuery;
    setKeyword(queries.search?.trim() || '');
    return queries;
  }, [searchParams]);

  const fetchLibraries = async (queries: LibraryQuery) => {
    queries.orderBy = queries.orderBy || 'popularity';
    const data = (await getLibraries({ ...queries })) || [];
    return mappingLibrary(data);
  };

  const { data: libraries, isLoading } = useQuery<Library[]>(
    ['libraries', searchTerm],
    () => fetchLibraries(searchTerm),
    { cacheTime: 1, staleTime: 300000, keepPreviousData: false },
  );

  const handleViewsModeChange = (mode: TViewMode) => {
    if (mode !== viewMode) {
      setViewMode(mode);
    }
  };

  const handleSetFilterBy = (filter: TNavBarValue) => {
    setSearchParams(prevValue => {
      const preQueries = Object.fromEntries(prevValue.entries());
      return { ...preQueries, orderBy: filter };
    });
  };

  useEffect(() => {
    uiStore.scrollToTop();
  }, [viewMode]);

  const search = (searchTerm: string) => {
    setKeyword(searchTerm);
  };

  useDebounce(
    () => {
      setSearchParams(prevValue => {
        const preQueries = Object.fromEntries(prevValue.entries());
        if (keyword === '') {
          delete preQueries.search;
          return { ...preQueries };
        }
        return { ...preQueries, search: keyword.trim() };
      });
    },
    500,
    [keyword],
  );
  const openModalUpload = () => {
    openModal('upload-content', {
      onAccept: () => {
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const renderContent = () => {
    if (!libraries?.length)
      return (
        <ErrorEmptyBlock
          title="New Content at your fingertips!"
          description="Click 'New Content' on the sidebar to create content."
        />
      );

    return viewMode === 'gallery' ? <LibraryGallery libraries={libraries} /> : <LibraryList libraries={libraries} />;
  };

  const renderRightElementHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <MichiAssistantBtn />
        <div className="mx-4 d-none d-md-block">
          <button
            className="btn btn-outline-primary"
            onClick={openModalUpload}
          >
            Upload
          </button>
        </div>
        <LibraryViewMode
          viewMode={viewMode}
          handleViewsModeChange={handleViewsModeChange}
        />
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title="Dashboard"
        rightElement={renderRightElementHeader()}
        subHeader={
          <LibraryNav
            filterBy={searchTerm.orderBy}
            handleSetFilterBy={handleSetFilterBy}
            keyword={keyword}
            search={search}
          />
        }
      />

      <div
        className="px-3 px-md-4 container-fluid libraryPage__container flex-fill d-flex flex-column"
        ref={libraryWrapperRef}
      >
        <div className="py-4 libraries flex-fill">{isLoading ? <Loading /> : renderContent()}</div>
      </div>
    </>
  );
};
