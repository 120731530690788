import { useAppStore } from 'hooks/useAppStore';
import React from 'react';
import { BarChart, Briefcase, Command, Edit, Home, PenTool } from 'react-feather';
import { SidebarMenuType } from 'types/sidebar';

export const useGetSidebarMenu = () => {
  const {
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  return [
    {
      icon: <Home size={18} />,
      title: 'Dashboard',
      link: '/dashboard',
      subMenu: [],
      actions: ['content.create'],
    },
    {
      icon: <Edit size={18} />,
      title: 'Content',
      link: '/contents',
      subMenu: [
        {
          title: 'All content',
          link: '/contents/all',
          actions: ['*'],
        },
        {
          title: 'My content',
          link: '/contents/mine',
          actions: ['*'],
        },
        {
          title: 'Shared with me',
          link: '/contents/shared',
          actions: ['*'],
        },
        {
          title: 'Trash',
          link: '/contents/trash',
          actions: ['*'],
        },
      ],
      actions: ['content.create'],
    },
    {
      icon: <BarChart size={18} />,
      title: 'Learner Report',
      link: '/learner-reports',
      actions: ['report'],
      hide: !currentSubscription.studentReportItem,
    },
    {
      icon: <Briefcase size={18} />,
      title: 'Organisation',
      link: '/organisation',
      subMenu: [
        {
          title: 'Manage users',
          link: '/organisation/users',
          actions: ['user'],
        },
        {
          title: 'Settings',
          link: '/organisation/settings',
          actions: ['organisation'],
        },
        {
          title: 'Subscription',
          link: '/organisation/subscription',
          actions: ['planBilling'],
        },
        {
          title: 'Billing information',
          link: '/organisation/billing',
          actions: ['planBilling'],
        },
        {
          title: 'Integrations',
          link: '/organisation/integrations',
          actions: ['lti'],
        },
      ],
      actions: ['user', 'organisation', 'planBilling', 'lti'],
    },
  ];
};

export const sidebarMenuAdmin: SidebarMenuType[] = [
  {
    icon: <PenTool size={18} />,
    title: 'Restrict Content Types',
    link: '/admin/restricted',
    subMenu: [],
    actions: ['*'],
  },
  {
    icon: <Command size={18} />,
    title: 'Manage Content Types',
    link: '/admin/manage',
    subMenu: [],
    actions: ['*'],
  },
];
