import React, { createContext, ReactElement, useCallback, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';

export type ModalType =
  | 'move-to-trash'
  | 'delete'
  | 'restore'
  | 'move-folder'
  | 'publish'
  | 'warning'
  | 'share-with'
  | 'new-folder'
  | 'publish'
  | 'warning'
  | 'content-filter'
  | 'content-actions'
  | 'rename-content'
  | 'invite-users'
  | 'create-workspace'
  | 'duplicate-content'
  | 'new-content'
  | 'user-actions'
  | 'add-lms-connection'
  | 'add-payment-method'
  | 'upload-content'
  | 'expired-plan'
  | 'payment-retry'
  | 'add-connection-settings'
  | 'report-filter'
  | 'content-attempts'
  | 'rename-chat'
  | '';

type ModalContextType = {
  isOpen: boolean;
  openModal: (modalType: ModalType, modalProps?: any) => void;
  closeModal: () => void;
  modalProps: any;
  type: ModalType;
};

export const ModalContext = createContext<ModalContextType | null>({
  isOpen: false,
  modalProps: {},
  type: '',
  //   eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  //   eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
});

export const ModalProvider = ({ children }: { children: ReactElement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<any>({});
  const [type, setType] = useState<ModalType>('');

  const openModal = useCallback(
    (modalType: ModalType, modalProps?: any) => {
      setIsOpen(true);
      setType(modalType);
      if (!_isEmpty(modalProps)) {
        setModalProps(modalProps);
      }
    },
    [isOpen, type, modalProps],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setType('');
  }, [isOpen]);

  return (
    <ModalContext.Provider value={{ isOpen, openModal, closeModal, modalProps, type }}>
      {children}
    </ModalContext.Provider>
  );
};
