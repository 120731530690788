import React from 'react';
import './AddPaymentMethod.scss';
import { X } from 'react-feather';
import { Footer } from '../Footer';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { syncPaymentMethod } from 'apis/Payment/PaymentMethod';
import { useAppStore } from 'hooks/useAppStore';
import { PaymentMethodData } from 'types/payment';

type AddPaymentMethodModalProps = {
  onCancel?: () => void;
  onAccept?: (paymentMethod: PaymentMethodData) => void;
  paymentIntent?: SetupIntent;
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const AddPaymentMethod = (props: AddPaymentMethodModalProps) => {
  const { onCancel, paymentIntent } = props;

  const options: StripeElementsOptions = {
    clientSecret: paymentIntent?.client_secret,
    appearance: {
      theme: 'stripe',
      variables: {
        fontSizeBase: '14px',
      },
    },
  };

  return (
    <div className="d-flex flex-column p-3 contents-filter-modal modal-wrapper">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="gray-900 fw-semibold font-size-18">New Payment Method</div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon me-0 gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <Elements
          stripe={stripePromise}
          options={options}
        >
          <CheckoutForm {...props} />
        </Elements>
      </div>
    </div>
  );
};

const CheckoutForm = observer(({ onCancel, onAccept }: AddPaymentMethodModalProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const {
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  const submit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      throw result.error;
    }

    return await syncPaymentMethod(result.setupIntent.payment_method as string);
  };

  const { mutate: handleSubmit, isLoading } = useMutation(submit, {
    onSuccess: data => {
      queryClient.invalidateQueries(['workspace', currentWorkspaceId, 'cards']);
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
      onCancel?.();
      toast('New payment method has been added successfully.');
      onAccept?.(data);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error',
      });
    },
  });

  return (
    <form>
      <PaymentElement />

      <div className="mt-3">
        <Footer
          labelCancel="Cancel"
          labelOK="Add Card"
          onOK={handleSubmit}
          onCancel={() => onCancel?.()}
          isLoading={isLoading}
          disabledOK={!stripe || isLoading}
        />
      </div>
    </form>
  );
});
