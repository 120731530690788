import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Paperclip, Trash2, Type } from 'react-feather';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import './ImportAndUpload.scss';
import { AnalyzeContentInputForm, ImportTab } from 'types';
import { useMutation } from 'react-query';
import { uploadFiles } from 'apis/AiChat/AiChat';
import { removeUUIDFromFilename } from 'utils';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { useAnalyzeContent } from 'hooks/ai-chat';
import AIMascot from '../AIMascot/AIMascot';
import { Loading } from 'components/Loading';
import { countWords, formatFileSize, formatFileType } from 'components/AIOffset/utils';
import { URL_REGEXP } from 'constants/regexp';
import BottomActions from '../BottomActions/BottomActions';
import { ChatInputLoading } from '../ChatInputLoading';

type Props = {
  chatId: string;
  analyzeSuccess: () => void;
  onBack: () => void;
};

const LIMIT_WORD = 5000;

const ImportAndUpload = ({ chatId, analyzeSuccess, onBack }: Props) => {
  const { mutate: analyzeContent, isLoading: isAnalyzing, isSuccess } = useAnalyzeContent();

  const { register, handleSubmit, watch, setValue } = useForm<AnalyzeContentInputForm>({
    defaultValues: {
      fileUrls: [],
      webUrls: [],
      text: '',
    },
  });
  const inputUrlRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [fileError, setFileError] = useState<string | null>(null);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const [urlError, setUrlError] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<ImportTab>(ImportTab.FILE);

  const { mutate: uploadMutate } = useMutation((files: File[]) => uploadFiles(chatId, files), {
    onMutate: files => {
      setPendingFiles(prev => [...prev, ...files]);
    },
    onSuccess: data => {
      setValue('fileUrls', [...watch('fileUrls'), ...data]);
      setPendingFiles([]);
    },
    onError: () => {
      setPendingFiles([]);
    },
  });

  const supportedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'text/plain',
  ];

  const watchFiles = watch('fileUrls');
  const watchUrls = watch('webUrls');
  const MAX_FILE_UPLOAD = 5;
  const MAX_FILE_SIZE_MB = 10;
  const FILE_SIZE_10MB = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB

  const handleTabClick = (tab: ImportTab) => {
    setSelectedTab(tab);
  };

  const onSubmit = async (data: AnalyzeContentInputForm) => {
    if (data.fileUrls?.length > MAX_FILE_UPLOAD) {
      setFileError(`You can only upload up to ${MAX_FILE_UPLOAD} files`);
      return;
    }

    setFileError(null);
    setUrlError(null);

    analyzeContent({
      chatId,
      payload: {
        fileUrls: data.fileUrls,
        webUrls: data.webUrls,
        text: data.text,
      },
    });
  };

  const validateAndUploadFiles = (files: File[]) => {
    const validFiles = files.filter(file => {
      const isValidType = supportedFileTypes.some(type => file.type.startsWith(type));
      const isValidSize = file.size <= FILE_SIZE_10MB;
      return isValidType && isValidSize;
    });

    const errorMessage = getErrorMessage(files, validFiles);
    setFileError(errorMessage);

    if (validFiles.length > MAX_FILE_UPLOAD) {
      setFileError(`You can only upload up to ${MAX_FILE_UPLOAD} files`);
      return;
    }

    if (validFiles.length > 0) {
      uploadMutate(validFiles);
    }
  };

  const getErrorMessage = (files: File[], validFiles: File[]): string | null => {
    if (files.length === 1 && files[0].size > FILE_SIZE_10MB) {
      return `File cannot exceed ${MAX_FILE_SIZE_MB}MB`;
    }
    if (validFiles.length !== files.length) {
      return `Some files were skipped due to unsupported type or size exceeding ${MAX_FILE_SIZE_MB}MB`;
    }
    return null;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      validateAndUploadFiles(files);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      validateAndUploadFiles(files);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const removeFile = (index: number) => {
    const updatedFiles = watchFiles.filter((_, i) => i !== index);
    setValue('fileUrls', updatedFiles);
    setFileError(null);
  };

  const addUrl = () => {
    const url = inputUrlRef.current?.value;

    if (!url) {
      setUrlError(null);
      return;
    }

    if (URL_REGEXP.test(url)) {
      setValue('webUrls', [...watchUrls, url]);
      inputUrlRef.current.value = '';
      setUrlError(null);
    } else {
      setUrlError('URL is invalid');
    }
  };

  const removeUrl = (index: number) => {
    const updatedUrls = watchUrls.filter((_, i) => i !== index);
    setValue('webUrls', updatedUrls);
  };

  const handleUrlChange = () => {
    setUrlError(null);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const words = value?.trim().split(/\s+/);

    if (words.length <= LIMIT_WORD) {
      setValue('text', value);
    } else {
      const truncatedValue = words.slice(0, LIMIT_WORD).join(' ');
      setValue('text', truncatedValue);
      e.target.value = truncatedValue;
    }
  };

  const handleTextareaInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('text');
    const combinedText = currentContent + (currentContent ? ' ' : '') + pastedText;
    const words = combinedText?.trim().split(/\s+/);
    const truncatedValue = words.slice(0, LIMIT_WORD).join(' ');

    setValue('text', truncatedValue);
    (e.target as HTMLTextAreaElement).value = truncatedValue;

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const content = watch('text');
    const words = content?.trim().split(/\s+/);
    if (words.length > LIMIT_WORD && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isSubmitDisabled = () => {
    const { fileUrls, webUrls, text } = watch();
    return fileUrls.length === 0 && webUrls.length === 0 && text?.trim() === '';
  };

  useEffect(() => {
    if (isSuccess) {
      analyzeSuccess();
    }
  }, [isSuccess]);

  if (isAnalyzing)
    return (
      <div className="p-4">
        <ChatInputLoading />
      </div>
    );

  return (
    <div className="p-4 brainstorming-content d-flex flex-column justify-content-between h-100">
      <div className="assistant">
        <div className="py-1 d-flex align-items-center">
          <AIMascot
            className="ai-mascot ai-mascot-small"
            faceSrc={happyFace}
            isFloat={false}
          />
          <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
        </div>
        <div className="guide bg-neutral-50 font-size-14 fw-normal text-neutral-900">
          Ready when you are. Select how you want to import. You can import from your local device, insert a link,
          and/or paste in text.
        </div>
      </div>
      <BottomActions loading={isAnalyzing}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="gap-3 d-flex flex-column w-100"
        >
          <div className="flex-row gap-3 d-flex align-items-center w-100">
            <div className="text-center tab-import d-flex flex-fill align-items-center">
              <div
                className={`font-size-14 fw-medium col-4 py-2 ${
                  selectedTab === ImportTab.FILE
                    ? 'text-primary-500 cursor-default selected-tab'
                    : 'text-neutral-500 cursor-pointer'
                }`}
                onClick={() => handleTabClick(ImportTab.FILE)}
              >
                <Paperclip
                  className="me-2"
                  width={20}
                  height={20}
                  strokeWidth={1.5}
                />
                Import file
              </div>
              <div
                className={`font-size-14 fw-medium col-4 py-2 ${
                  selectedTab === ImportTab.URL
                    ? 'text-primary-500 cursor-default selected-tab'
                    : 'text-neutral-500 cursor-pointer'
                }`}
                onClick={() => handleTabClick(ImportTab.URL)}
              >
                <Link
                  className="me-2"
                  width={20}
                  height={20}
                  strokeWidth={1.5}
                />
                Import URL
              </div>
              <div
                className={`font-size-14 fw-medium col-4 py-2 ${
                  selectedTab === ImportTab.TEXT
                    ? 'text-primary-500 cursor-default selected-tab'
                    : 'text-neutral-500 cursor-pointer'
                }`}
                onClick={() => handleTabClick(ImportTab.TEXT)}
              >
                <Type
                  className="me-2"
                  width={20}
                  height={20}
                  strokeWidth={1.5}
                />
                Import text
              </div>
            </div>
          </div>

          {selectedTab === ImportTab.FILE && (
            <>
              <div className="p-3 import-file-section bg-neutral-50">
                {watchFiles.length > 0 && (
                  <div className="gap-2 mb-2 d-flex flex-column">
                    {watchFiles.map((file, index) => (
                      <div
                        className="list-file"
                        key={`uploaded-${index}`}
                      >
                        <div className="mb-1 d-flex align-items-center justify-content-between">
                          <div className="file-name font-size-14 fw-normal text-neutral-900">
                            {removeUUIDFromFilename(file.name)}
                          </div>
                          <Trash2
                            size={16}
                            className="flex-shrink-0 cursor-pointer trash-icon"
                            onClick={() => removeFile(index)}
                          />
                        </div>
                        <div className="flex-row gap-2 d-flex align-items-center font-size-12 fw-normal text-neutral-500">
                          <div>{formatFileType(file.contentType)}</div>
                          <div className="file-upload-divider"></div>
                          <div>{formatFileSize(file.fileSize)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {pendingFiles.length > 0 && (
                  <div className="gap-2 mb-2 d-flex flex-column">
                    {pendingFiles.map((file, index) => (
                      <div
                        className="list-file"
                        key={`pending-${index}`}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <Loading size="sm" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="p-3 text-center cursor-pointer drag-drop"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept={supportedFileTypes.join(', ')}
                    style={{ display: 'none' }}
                    id="fileInput"
                    multiple
                    ref={fileInputRef}
                  />
                  <div
                    className="gap-1 d-flex flex-column"
                    onClick={handleUploadClick}
                  >
                    <div className="font-size-14 fw-medium text-neutral-900">
                      <span className="text-primary-500">Upload a file</span> or drag and drop
                    </div>
                    <div className="font-size-12 fw-normal text-neutral-400">
                      Support files: PDF, DOC, DOCX, PPT, PPTX, and TXT
                    </div>
                  </div>
                </div>
                {fileError && <div className="mt-1 font-size-14 text-error-500">{fileError}</div>}
              </div>
            </>
          )}
          {selectedTab === ImportTab.URL && (
            <>
              <div
                className="p-3 import-file-section d-flex flex-column bg-neutral-50"
                style={{ gap: '12px' }}
              >
                <div className="fonts-size-14 fw-normal text-neutral-500">
                  Webpage URLs should contain text, while video URLs should offer a transcript for Michi to process.
                </div>
                {watchUrls.length > 0 &&
                  watchUrls.map((url, index) => (
                    <div
                      className="list-file"
                      key={index}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="file-name font-size-14 fw-normal text-neutral-900 text-decoration-underline">
                          {url}
                        </div>
                        <Trash2
                          size={16}
                          className="flex-shrink-0 cursor-pointer trash-icon"
                          onClick={() => removeUrl(index)}
                        />
                      </div>
                    </div>
                  ))}
                <div className="gap-2 d-flex flex-column">
                  <div className="flex-row gap-2 d-flex">
                    <div className="d-flex flex-fill align-items-center search-wrapper">
                      <input
                        ref={inputUrlRef}
                        className="search-input d-flex align-items-center h-100 flex-fill w-1px text-truncate"
                        type="text"
                        placeholder="Enter URL and click Add"
                        onChange={handleUrlChange}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={addUrl}
                    >
                      Add
                    </button>
                  </div>
                  {urlError && <div className="font-size-14 text-error-500">{urlError}</div>}
                </div>
              </div>
            </>
          )}
          {selectedTab === ImportTab.TEXT && (
            <>
              <div className="gap-2 p-3 import-file-section d-flex flex-column bg-neutral-50">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="font-size-14 fw-medium text-neutral-900">Type or paste your text here</div>
                  <div className="d-flex align-items-center justify-content-end fonts-size-12 fw-normal text-neutral-400">
                    {countWords(watch('text'))}/{LIMIT_WORD}
                  </div>
                </div>
                <textarea
                  {...register('text')}
                  className="area-input d-flex align-items-center flex-fill"
                  placeholder="Describe your learning material. You can either type it or paste it directly."
                  rows={1}
                  onChange={handleTextareaChange}
                  onPaste={handlePaste}
                  onKeyDown={handleKeyDown}
                  onInput={handleTextareaInput}
                  ref={textareaRef}
                />
              </div>
            </>
          )}

          <div className="d-flex align-items-center justify-content-between">
            <div
              className="cursor-pointer back-btn font-size-14 fw-medium"
              onClick={onBack}
            >
              Back
            </div>

            <button
              className="p-2 text-white btn btn-primary rounded-circle d-flex justify-content-center align-items-center"
              type="submit"
              disabled={isSubmitDisabled()}
            >
              <ArrowRightIcon
                className="text-white"
                width={16}
                height={16}
                strokeWidth={2}
              />
            </button>
          </div>
        </form>
      </BottomActions>
    </div>
  );
};

export default ImportAndUpload;
