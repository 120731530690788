import React from 'react';
import { cancelSubscription, resumeSubscription } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import useModal from 'hooks/useModal';
import moment from 'moment';
import { FileText } from 'react-feather';
import { toast } from 'react-toastify';
import {
  useActivateAIAssistant,
  useActivateStudentReport,
  useDeactivateAIAssistant,
  useDeactivateStudentReport,
  useReactivateAIAssistant,
  useReactivateStudentReport,
} from 'hooks';
import { useQueryClient } from 'react-query';

export const useSubscriptionModal = () => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const {
    subscriptionStore: { currentSubscription, updateCurrentSubscription },
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();
  const { mutateAsync: activateStudentReport } = useActivateStudentReport();
  const { mutateAsync: deactivateStudentReport } = useDeactivateStudentReport();
  const { mutateAsync: reactivateStudentReport } = useReactivateStudentReport();
  const { mutateAsync: activateAIAssistant } = useActivateAIAssistant();
  const { mutateAsync: deactivateAIAssistant } = useDeactivateAIAssistant();
  const { mutateAsync: reactivateAIAssistant } = useReactivateAIAssistant();

  const subscriptionEndsAt = moment(currentSubscription?.currentPeriodEnd).format('LL');

  const openCancelPlanModal = (cancelAtPeriodEnd: boolean) => {
    const title = `Are you sure you want to ${cancelAtPeriodEnd ? 'resume' : 'cancel'} plan?`;
    const content = cancelAtPeriodEnd
      ? `This plan will no longer be canceled. It will renew on ${subscriptionEndsAt}`
      : `Your plan will be canceled, but is still available until the end of your billing period on ${subscriptionEndsAt}. If you change your mind, you can resume your subscription.`;
    const updateAction = cancelAtPeriodEnd ? resumeSubscription : cancelSubscription;

    openModal('warning', {
      title,
      content,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      onCancel: () => {
        closeModal();
      },
      onAccept: async (done: () => void) => {
        await updateAction();
        updateCurrentSubscription({ ...currentSubscription, cancelAtPeriodEnd: !cancelAtPeriodEnd });
        queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
        done();
        toast(`Subscription has been ${cancelAtPeriodEnd ? 'resumed' : 'canceled'}.`);
        closeModal();
      },
    });
  };

  const openActivateStudentReportModal = (onSuccess: () => void) => {
    const isLearnerReportActivatedBefore = !!currentSubscription.studentReportItem;
    const title = isLearnerReportActivatedBefore ? 'Reactivate Learner Report?' : 'Activate Learner Report';
    const content = isLearnerReportActivatedBefore
      ? `Learner Report will no longer be deactivated. It will renew on ${subscriptionEndsAt}.`
      : 'Use now, pay later! You can deactivate at any time, and your cancellation will take effect at the end of the billing period. Please note that you will be charged for the prorated usage during that cycle.';
    const action = isLearnerReportActivatedBefore ? reactivateStudentReport : activateStudentReport;
    const labelOK = isLearnerReportActivatedBefore ? 'Yes, Reactivate' : 'Yes, Activate';

    openModal('warning', {
      title,
      content,
      titleIcon: (
        <FileText
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK,
      onAccept: async (done: () => void) => {
        try {
          await action();
          done();
          onSuccess();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  const openDeactivateStudentReportModal = () => {
    openModal('warning', {
      title: 'Deactivate Learner Report?',
      content: `Learner Report will be deactivated, but it will remain available until the end of your billing period on ${subscriptionEndsAt}. If you change your mind, you can reactivate it at any time.`,
      labelOK: 'Deactivate Learner Report',
      labelCancel: 'Go Back',
      displayTitleIcon: false,
      onAccept: async (done: () => void) => {
        try {
          await deactivateStudentReport();
          done();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  const openActivateAIAssistantModal = (onSuccess: () => void) => {
    const isAIAssistantActivatedBefore = !!currentSubscription.aiAssistantItem;
    const title = isAIAssistantActivatedBefore ? 'Reactivate Michi Assistant?' : 'Activate Michi Assistant?';
    const content = isAIAssistantActivatedBefore
      ? `Michi Assistant will no longer be deactivated. It will renew, and you will be charged for the prorated usage on ${subscriptionEndsAt}.`
      : 'Use now, pay later! You can deactivate at any time, and your cancellation will take effect at the end of the billing period. Please note that you will be charged for the prorated usage during that cycle.';
    const action = isAIAssistantActivatedBefore ? reactivateAIAssistant : activateAIAssistant;
    const labelOK = isAIAssistantActivatedBefore ? 'Yes, Reactivate' : 'Yes, Activate';

    openModal('warning', {
      title,
      content,
      titleIcon: (
        <FileText
          className="primary-500"
          strokeWidth={1.5}
          size={24}
        />
      ),
      labelOK,
      onAccept: async (done: () => void) => {
        try {
          await action();
          done();
          onSuccess();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  const openDeactivateAIAssistantModal = () => {
    openModal('warning', {
      title: 'Deactivate Michi Assistant?',
      content: `Michi Assistant will be deactivated, but it will remain available until the end of your billing period on ${subscriptionEndsAt}. If you change your mind, you can reactivate it at any time.`,
      labelOK: 'Deactivate AI',
      labelCancel: 'Go Back',
      displayTitleIcon: false,
      onAccept: async (done: () => void) => {
        try {
          await deactivateAIAssistant();

          done();
        } finally {
          done();
          closeModal();
        }
      },
      onCancel: closeModal,
    });
  };

  return {
    openCancelPlanModal,
    openDeactivateStudentReportModal,
    openActivateStudentReportModal,
    openActivateAIAssistantModal,
    openDeactivateAIAssistantModal,
  };
};
