import React, { useEffect, useMemo, useRef, useState } from 'react';
import './ChatMessagesHistory.scss';
import classNames from 'classnames';
import {
  addMessageToChatHistory,
  useApplyContent,
  useCreateMessage,
  useFetchChatDetail,
  useFetchChatHistory,
  useUpdateChat,
} from 'hooks/ai-chat';
import {
  AIMessage,
  AIMessageActionType,
  AIMessageContentType,
  AIMessageType,
  AiChatHistory,
  AiChatType,
  ChatDetailResponse,
} from 'types';
import { ChatInput } from '../ChatInput';
import UserUploadPrompt from '../UserUploadPrompt/UserUploadPrompt';
import { AppBadge } from 'components/AppBadge';
import { ChevronRight } from 'react-feather';
import { useQueryClient } from 'react-query';
import ChatMessages from '../ChatMessages/ChatMessages';
import AIMascot from '../AIMascot/AIMascot';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { getBrainstormTopicSuggestions } from 'components/AIOffset';
import { Loading } from 'components/Loading';
import BottomActions from '../BottomActions/BottomActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'hooks/useAppStore';
import selectContent from 'assets/images/ai-assistant/message-actions/select-content.svg';
import applyCreate from 'assets/images/ai-assistant/message-actions/apply-create.svg';
import RefineModal from '../RefineModal/RefineModal';
import SelectContentModal from '../SelectContentModal/SelectContentModal';
import ActionButtons from '../ActionButtons/ActionButtons';
import { HelpTipsProvider } from 'components/AIOffset/components/HelpTips/HelpTipsProvider';
import HelpTips from 'components/AIOffset/components/HelpTips/HelpTips';
import {
  REFINE_CONTENT_TIP,
  SELECT_OTHER_TIP,
  APPLY_CREATE_TIP,
  SELECT_CONTENT_TIP,
  REFINE_CONTEXT_TIP,
} from 'constants/help-tip';
import { useRegenerateMessage } from 'hooks/ai-chat/useRegenerateMessage';

export const ChatMessagesHistory = ({ chatId }: { chatId: string; selectChat: (id: string) => void }) => {
  const { data: chatDetail, isLoading: isGetChatDetailLoading } = useFetchChatDetail(chatId);
  const messages = chatDetail?.messages || [];
  const importedDocuments = chatDetail?.aiChat?.importedDocuments || {};
  const queryClient = useQueryClient();
  const { data: chatHistory = [] } = useFetchChatHistory();
  const { mutateAsync: createNewMessage, isLoading: isCreateMessageLoading } = useCreateMessage();
  const { mutateAsync: analyzeContent, isLoading: isAnalyzing } = useCreateMessage();
  const { mutateAsync: refine, isLoading: isRefineLoading } = useCreateMessage();
  const { mutateAsync: generateContentMessage, isLoading: isGenerateLoading } = useCreateMessage();
  const { mutateAsync: regenerateMessage, isLoading: isRegenerateLoading } = useRegenerateMessage();
  const { mutate: updateChat } = useUpdateChat({});
  const bottomElementRef = useRef<HTMLDivElement>();
  const [showChatInput, setShowChatInput] = useState(true);

  const [isRefineModalOpen, setIsRefineModalOpen] = useState(false);
  const [isSelectContentTypeOpen, setIsSelectContentTypeOpen] = useState(false);
  const [currentActionType, setCurrentActionType] = useState<AIMessageActionType | null>(null);
  const [currentMessageContentType, setCurrentMessageContentType] = useState<AIMessageContentType | null>(null);
  const { mutateAsync: applyContent, isLoading: isApplyLoading } = useApplyContent();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const {
    ltiStore: { workspaceId },
    ltiMode,
    uiStore,
  } = useAppStore();
  const lastMessage = messages[messages.length - 1];
  const messageContentType = lastMessage?.messageContentType;
  const renderHelpTips = !isRefineModalOpen && !isSelectContentTypeOpen && !isRegenerateLoading;

  const getHelpTips = () => {
    if (messageContentType === AIMessageContentType.Content) {
      return [APPLY_CREATE_TIP, REFINE_CONTENT_TIP, SELECT_OTHER_TIP];
    }
    if (messageContentType === AIMessageContentType.Context) {
      return [SELECT_CONTENT_TIP, REFINE_CONTEXT_TIP];
    }
    return [];
  };

  const helpTips = getHelpTips();

  const brainstormChats = useMemo<AiChatHistory[]>(() => {
    return chatHistory.filter(chat => chat.chatType === AiChatType.BRAINSTORM);
  }, [chatHistory]);

  const defaultChats = useMemo<Partial<AiChatHistory>[]>(() => {
    return brainstormChats.length ? brainstormChats : getBrainstormTopicSuggestions();
  }, [chatHistory]);

  const onAnalyzeBrainstormingContent = async () => {
    setShowChatInput(false);
    const lastMessage = messages[messages.length - 1].content;

    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        messageType: AIMessageType.Human,
        content: 'Finish Brainstorm & Analyse Content',
        messageContentType: AIMessageContentType.Text,
        actionType: AIMessageActionType.Chat,
      },
    });

    setTimeout(scrollToBottom, 100);

    const dataMessage = await analyzeContent({
      chatId,
      content: lastMessage,
      actionType: AIMessageActionType.GenerateContext,
    });

    queryClient.setQueryData<ChatDetailResponse>(['chat-detail', chatId], chatDetail => {
      return {
        ...chatDetail,
        aiChat: {
          ...chatDetail.aiChat,
          aiContext: dataMessage.content,
        },
      };
    });
  };

  const onSubmitInput = async ({
    chatId,
    content,
    actionType,
  }: {
    chatId: string;
    content: string;
    actionType: AIMessageActionType;
  }) => {
    const newInputMessage = await createNewMessage({
      chatId,
      content,
      actionType,
    });

    // Add new chat to sidebar history when user first input in brainstorm
    const hasMessagesBefore = messages.length > 0;
    if (!hasMessagesBefore) {
      updateChatName(newInputMessage.content.split('.')[0]);
    }
  };

  const updateChatName = (name: string) => {
    updateChat({ chatId, name });

    queryClient.setQueryData<AiChatHistory[]>('chat-history', (chatsList: AiChatHistory[] = []) => {
      return [{ ...chatDetail.aiChat, name }, ...chatsList];
    });
  };

  const isChatAnalyzed = useMemo(() => {
    return !!chatDetail?.aiChat?.aiContext;
  }, [chatDetail]);

  const welcomeMessage = useMemo(() => {
    return chatDetail?.aiChat?.chatType === AiChatType.IMPORT ? (
      <div className="d-flex justify-content-end">
        <UserUploadPrompt
          chatId={chatId}
          importedDocuments={importedDocuments}
        />
      </div>
    ) : (
      !messages.length && (
        <div className="gap-2 d-flex flex-column">
          <div className="d-flex align-items-center">
            <AIMascot
              className="ai-mascot ai-mascot-small"
              faceSrc={happyFace}
              isFloat={false}
            />
            <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
          </div>
          <div className="guide bg-neutral-50 font-size-14 fw-normal text-neutral-900">
            Hello! What are we exploring today?
          </div>
        </div>
      )
    );
  }, [chatDetail?.aiChat?.chatType, messages?.length]);

  const scrollToBottom = () => {
    if (!bottomElementRef.current) return;

    const scrollContainer = bottomElementRef.current.closest('.overflow-auto');
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
      });
    }
  };

  const handleAction = async (message: AIMessage) => {
    if ([AIMessageActionType.GenerateContent, AIMessageActionType.RefineContent].includes(message.actionType)) {
      const data = await applyContent({ chatId, messageId: message.id });
      const library = data.metadata.structuredContent.library;
      const messageId = data.id;
      addNew(library, messageId);
      setTimeout(() => {
        uiStore.toggleAIOffset();
      }, 500);
    } else {
      handleOpenSelectContentModal();
    }
  };

  const handleRefineClick = (actionType: AIMessageActionType, messageContentType: AIMessageContentType) => {
    setCurrentActionType(actionType);
    setCurrentMessageContentType(messageContentType);
    setIsRefineModalOpen(true);
    setIsSelectContentTypeOpen(false);
    setTimeout(scrollToBottom, 100);
  };

  const handleOpenSelectContentModal = () => {
    setIsSelectContentTypeOpen(true);
    setIsRefineModalOpen(false);
    setTimeout(scrollToBottom, 100);
  };

  const handleCloseSelectContentModal = () => {
    setIsSelectContentTypeOpen(false);
  };

  const handleCloseRefineModal = () => {
    setIsRefineModalOpen(false);
    setCurrentActionType(null);
    setCurrentMessageContentType(null);
  };

  const addNew = (library: string, messageId: string) => {
    const navigateFrom = state?.from ?? location.pathname;
    const shouldRefresh = true;
    const url = ltiMode ? `/${workspaceId}/lti/launch` : '';

    navigate(`${url}/contents/new?library=${library}&aiMessageId=${messageId}`, {
      replace: true,
      state: {
        shouldRefresh,
        from: navigateFrom,
      },
    });
  };

  const actionLabel = (actionType: AIMessageActionType) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent)
      return 'Apply & Create';

    return 'Select Content';
  };

  const renderIcon = (actionType: AIMessageActionType) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent)
      return applyCreate;

    return selectContent;
  };

  const getRefineLabel = (actionType: AIMessageActionType | null) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent) {
      return 'Refine Content';
    }
    return 'Refine Context';
  };

  const renderModal = () => {
    if (isRefineModalOpen) {
      return (
        <RefineModal
          label={getRefineLabel(currentActionType)}
          onClose={handleCloseRefineModal}
          currentMessageContentType={currentMessageContentType}
          chatId={chatId}
          scrollBottom={scrollToBottom}
          onSubmit={refine}
        />
      );
    }

    if (isSelectContentTypeOpen) {
      return (
        <SelectContentModal
          onClose={handleCloseSelectContentModal}
          chatId={chatId}
          scrollBottom={scrollToBottom}
          generateContentMessage={generateContentMessage}
        />
      );
    }

    return renderActionButtons();
  };

  const renderActionButtons = () => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      return (
        <ActionButtons
          lastMessage={lastMessage}
          isApplyLoading={isApplyLoading}
          isRefineLoading={isRefineLoading}
          isGenerateLoading={isGenerateLoading}
          isAnalyzing={isAnalyzing}
          handleAction={handleAction}
          handleRefineClick={handleRefineClick}
          handleOpenSelectContentModal={handleOpenSelectContentModal}
          renderIcon={renderIcon}
          actionLabel={actionLabel}
          getRefineLabel={getRefineLabel}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isCreateMessageLoading) {
      setTimeout(scrollToBottom, 100);
    }
  }, [isCreateMessageLoading]);

  const onSelectDefaultMessage = async (content: string) => {
    addMessageToChatHistory({
      queryClient,
      chatId,
      message: {
        content,
        actionType: AIMessageActionType.Chat,
        messageContentType: AIMessageContentType.Text,
        messageType: AIMessageType.Human,
      },
    });

    await onSubmitInput({ chatId, content, actionType: AIMessageActionType.Chat });
  };

  if (isGetChatDetailLoading) {
    return <Loading />;
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1 overflow-auto mb-3">
        <div
          className={classNames('chat-messages-history bg-white p-4 pb-0 h-100 d-flex flex-column', {
            'pb-0': chatDetail?.aiChat?.chatType === AiChatType.BRAINSTORM,
          })}
        >
          {welcomeMessage}
          <div className="bg-white gap-2 flex-fill d-flex flex-column h-100 mb-2">
            <ChatMessages
              isChatAnalyzed={!!messages.length && isChatAnalyzed}
              chatId={chatId}
              messages={messages}
              isNewMessageLoading={isCreateMessageLoading}
              isAnalyzing={isAnalyzing}
              isRefineLoading={isRefineLoading}
              isSelectContentLoading={isGenerateLoading}
              regenerateMessage={regenerateMessage}
              isRegenerateLoading={isRegenerateLoading}
            />
          </div>

          <div ref={bottomElementRef} />
        </div>
      </div>

      <div className="bg-white px-4">
        {chatDetail?.aiChat?.chatType === AiChatType.BRAINSTORM && (
          <>
            {!messages.length && (
              <div className="gap-2 mb-5 d-flex flex-column">
                {brainstormChats.length > 0 && (
                  <div className="d-flex align-items-center justify-content-end fonts-size-14 fw-medium text-neutral-500">
                    Your previous brainstorming topics...
                  </div>
                )}

                {defaultChats.slice(0, 2).map((chat, index) => {
                  return (
                    <div
                      onClick={() => onSelectDefaultMessage(chat.name)}
                      key={index}
                      className="cursor-pointer d-flex align-items-center justify-content-end font-size-14 custom-badge"
                    >
                      <AppBadge
                        type="neutral-outline-hover"
                        label={
                          <div className="w-100 d-flex align-items-center">
                            <div className="previous-brainstorm-topic w-100">{chat.name}</div>

                            <ChevronRight
                              size={16}
                              className="text-neutral-900"
                            />
                          </div>
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {messages.length > 1 && !isChatAnalyzed && !isAnalyzing && !isCreateMessageLoading && (
              <div className="gap-2 mb-5 d-flex flex-column">
                <div
                  onClick={() => onAnalyzeBrainstormingContent()}
                  className="cursor-pointer d-flex align-items-center justify-content-end font-size-14 custom-badge"
                >
                  <AppBadge
                    type="primary-outline-hover"
                    label={
                      <>
                        Finish Brainstorm & Analyse Content
                        <ChevronRight
                          size={16}
                          className="text-neutral-900 ms-2"
                        />
                      </>
                    }
                  />
                </div>
              </div>
            )}

            {!isChatAnalyzed && showChatInput && (
              <ChatInput
                chat={chatDetail}
                submitInput={onSubmitInput}
                isLoading={isCreateMessageLoading}
              />
            )}
          </>
        )}

        {((!!messages.length && isChatAnalyzed) || isAnalyzing) && (
          <BottomActions
            renderHelpTips={() =>
              renderHelpTips && (
                <div className="mb-2">
                  <HelpTipsProvider defaultOpen={false}>
                    <HelpTips
                      helpTips={helpTips}
                      key={messageContentType}
                    />
                  </HelpTipsProvider>
                </div>
              )
            }
            isWelcomeSection={false}
            loading={isRefineLoading || isGenerateLoading || isAnalyzing || isRegenerateLoading}
          >
            {renderModal()}
          </BottomActions>
        )}
      </div>
    </div>
  );
};
