import { Role } from '@spiderbox/common/dist/src/user/user.enum';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  isOwner: boolean;
  roles: string[];
}

export const UserRoleDisplay: React.FC<Props> = observer(({ isOwner, roles }) => {
  const rolePill = (role: string) => (
    <span className="font-weight-medium px-2 py-1 rounded-pill cursor-default role-title">{role}</span>
  );

  return (
    <div className="d-flex gap-2">
      {isOwner && rolePill('Owner')}
      {roles?.includes(Role.ADMIN) && rolePill('Admin')}
      {roles?.includes(Role.MEMBER) && rolePill('Member')}
      {roles?.includes(Role.COURSE_TEACHER) && rolePill('Trainer')}
      {roles?.includes(Role.STUDENT) && rolePill('Student')}
    </div>
  );
});
