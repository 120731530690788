import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Library } from 'types';
import { ChevronsRight, Plus } from 'react-feather';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import classNames from 'classnames';
import { appStore } from 'stores';
import { useAppStore } from 'hooks/useAppStore';
import moment from 'moment';
import './LibraryContent.scss';

export const LibraryContent = ({ library, index }: { library: Library; index?: number }) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { folderId = '', id: contentId = '' } = useParams();
  const {
    ltiStore: { workspaceId },
    ltiMode,
  } = useAppStore();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { state } = location;

  const addNew = () => {
    closeModal();
    const navigateFrom = state?.from ? state?.from : location.pathname;
    const shouldRefresh = !!contentId || searchParams.get('library')?.includes(library.metadata.machineName);

    const url = ltiMode ? `/${workspaceId}/lti/launch` : '';
    navigate(
      `${url}/contents/new?library=${library.metadata.machineName} ${library.metadata.majorVersion}.${library.metadata.minorVersion}&folderId=${folderId}`,
      {
        replace: true,
        state: {
          shouldRefresh,
          from: navigateFrom,
        },
      },
    );
  };

  const readMore = () => {
    console.log('readMore');
  };

  return (
    <>
      <div className="d-flex d-lg-none flex-row">
        <img
          className="me-3"
          style={{ height: '80px', width: '80px' }}
          src={library?.image}
        />
        <div className="d-flex flex-fill justify-content-center flex-column gap-2">
          <p
            className="title p-0 m-0 fw-bold text-neutral-900"
            data-cy="content-title"
          >
            {library.title}
          </p>
          <div className="d-flex ">
            <p className="library-content-description p-0 m-0 fw-normal text-neutral-600 flex-fill">
              <span
                data-cy="content-description"
                id={'library-list-item-' + index}
              >
                {library.description}
              </span>

              <UncontrolledTooltip
                className="d-none d-sm-block"
                container="body"
                placement="bottom"
                target={'library-list-item-' + index}
              >
                {library?.description}
              </UncontrolledTooltip>
            </p>
          </div>
        </div>
      </div>
      <img
        className="d-none d-lg-flex me-3"
        style={{ height: '80px', width: '80px' }}
        src={library?.image}
      />
      <p
        className="d-none d-lg-flex col-3 title p-0 m-0 fw-bold text-neutral-900 "
        data-cy="content-title"
      >
        {library.title}
      </p>
      <div className="d-none d-lg-flex col-3">
        <p className="description p-0 m-0 fw-normal text-neutral-600 flex-fill w-1px text-truncate">
          <span
            data-cy="content-description"
            id={'library-list-item-' + index}
          >
            {library.description}
          </span>

          <UncontrolledTooltip
            className="d-none d-sm-block"
            container="body"
            placement="bottom"
            target={'library-list-item-' + index}
          >
            {library?.description}
          </UncontrolledTooltip>
        </p>
      </div>
      <p className="d-none d-lg-flex p-0 m-0 col-3 align-items-center justify-content-end fw-normal text-neutral-600">
        {library.lastUsed ? `Last used: ${moment(library.lastUsed).format('DD/MM/YYYY HH:mm')}` : ''}
      </p>
      <div className="libraryList__actions d-flex flex-fill justify-content-end">
        <a
          data-cy="btn-detail"
          className="d-none"
        >
          <ChevronsRight
            size={36}
            className="libraryList__actions--icon border-primary-300 primary-300 d-inline-block border rounded-circle"
            onClick={readMore}
          />
        </a>
        <a
          className={classNames('d-none', { 'd-sm-block': appStore.ltiMode, 'd-lg-block': !appStore.ltiMode })}
          data-cy="btn-add-new"
        >
          <Plus
            size={36}
            className="libraryList__actions--icon border-primary-300 primary-300 d-inline-block border rounded-circle"
            onClick={addNew}
          />
        </a>
      </div>
    </>
  );
};
