import React, { useEffect } from 'react';
import classNames from 'classnames';
import AvatarProfile from 'components/AvatarProfile/AvatarProfile';
import { useAppStore } from 'hooks/useAppStore';
import useModal from 'hooks/useModal';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { WorkspaceInfo } from 'types';
import { observer } from 'mobx-react-lite';
import { UserPermission } from 'components/UserPermission/UserPermission';

const DISTANCE_SCROLL = 40;
const DISABLE_CHEVRON_DOWN_BY_WORKSPACES = 3;
const FLEX_GAP = 4;

interface Props {
  toggleOpen: () => void;
}

export const WorkspacesList = observer(({ toggleOpen }: Props) => {
  const [disableChevronUp, setDisableChevronUp] = useState<boolean>(true);
  const [disableChevronDown, setDisableChevronDown] = useState<boolean>(false);

  const listWorkspacesRef = useRef<HTMLDivElement>(null);

  const {
    workspaceStore: { workspaces = [], currentWorkspace },
  } = useAppStore();
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();

  useEffect(() => {
    if (workspaces.length <= DISABLE_CHEVRON_DOWN_BY_WORKSPACES) {
      setDisableChevronDown(true);
    }
  }, []);

  const handleScrollUp = () => {
    const listWorkspaceCurrent = listWorkspacesRef.current;

    listWorkspaceCurrent?.scrollBy({
      top: -DISTANCE_SCROLL,
      behavior: 'smooth',
    });

    if (listWorkspaceCurrent.scrollTop <= 0) {
      setDisableChevronUp(true);
    } else {
      setDisableChevronUp(false);
    }
  };

  const newWorkspacesSorted = useMemo(() => {
    const newWorkspaces = [...workspaces];

    const currentWorkspaceSelectedIndex = newWorkspaces.findIndex(wp => wp.id === currentWorkspace?.id);

    const currentWorkspaceSelected = newWorkspaces.splice(currentWorkspaceSelectedIndex, 1);

    return [...currentWorkspaceSelected, ...newWorkspaces];
  }, [workspaces]);

  const handleScrollDown = () => {
    const listWorkspaceCurrent = listWorkspacesRef.current;

    listWorkspaceCurrent.scrollBy({
      top: DISTANCE_SCROLL,
      behavior: 'smooth',
    });

    if (
      listWorkspaceCurrent.scrollHeight - listWorkspaceCurrent.scrollTop <=
      listWorkspaceCurrent.clientHeight + FLEX_GAP
    ) {
      setDisableChevronDown(true);
    } else {
      setDisableChevronDown(false);
    }
  };

  const onScroll = e => {
    if (e.target.scrollTop <= 0) {
      setDisableChevronUp(true);
    } else {
      setDisableChevronUp(false);
    }

    if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + FLEX_GAP) {
      setDisableChevronDown(true);
    } else {
      setDisableChevronDown(false);
    }
  };

  const switchWorkspace = (data: WorkspaceInfo) => {
    navigate('/workspace/switch', { state: { data } });
  };

  const handleOpenModalNewWorkspace = useCallback(() => {
    openModal('create-workspace', {
      onCancel: () => closeModal(),
    });
    toggleOpen();
  }, [openModal]);

  return (
    <div className="flex-fill d-flex flex-column align-items-center justify-content-between p-3 sidebar-profile__popover__workspaces-list">
      <div
        className={classNames('sidebar-profile__chevron', {
          'sidebar-profile__chevron--disable': disableChevronUp,
        })}
        onClick={handleScrollUp}
        data-cy="sidebar-profile__chevron-up-list-wp"
      >
        <ChevronUp />
      </div>

      <div className="d-flex flex-column align-items-center mb-2 h-100">
        <div
          ref={listWorkspacesRef}
          className="row gy-1 justify-content-center overflow-scroll mt-1"
          style={{ maxHeight: '120px' }}
          onScroll={onScroll}
          data-cy="sidebar-profile__list-wp"
        >
          {newWorkspacesSorted.map((wp, index) => (
            <div
              className="cursor-pointer"
              key={index}
              onClick={() => switchWorkspace({ ...wp })}
            >
              <div id={`tooltip-workspace-${index}`}>
                <AvatarProfile
                  imgSrc={wp.imageUrl}
                  width={36}
                  height={36}
                  userFullName={wp.name}
                  hasOutline={wp.id === currentWorkspace?.id ? true : false}
                  avatarClassNames="sidebar-profile__avatar"
                />
              </div>

              <UncontrolledTooltip
                placement="top"
                target={`tooltip-workspace-${index}`}
              >
                {wp.name}
              </UncontrolledTooltip>
            </div>
          ))}
        </div>
      </div>

      <UserPermission actions={['organisation']}>
        <div className="d-flex flex-column align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle text-primary sidebar-profile__popover__add-workspace"
            onClick={handleOpenModalNewWorkspace}
            data-cy="sidebar-profile__popover__add-workspace"
          >
            <Plus
              className="text-primary"
              size={14}
              strokeWidth={3}
            />
          </div>

          <ChevronDown
            className={classNames('mt-2 sidebar-profile__chevron', {
              'sidebar-profile__chevron--disable': disableChevronDown,
            })}
            onClick={handleScrollDown}
            data-cy="sidebar-profile__chevron-down-list-wp"
          />
        </div>
      </UserPermission>
    </div>
  );
});
