import React, { useRef } from 'react';
import { ContentType } from '@spiderbox/common';
import { addMessageToChatHistory } from 'hooks/ai-chat';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Select, { components, ControlProps, InputProps, OptionProps } from 'react-select';
import { AIMessageActionType, AIMessageContentType, AIMessageType, SelectContentTypeInputForm } from 'types';
import ModalSuggestions from 'components/AIOffset/components/ModalSuggestions/ModalSuggestions';
import { SELECT_CONTENT_BADGE } from 'constants/modal-suggestions';
import { countWords } from 'components/AIOffset/utils';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

import './SelectContentModal.scss';

type Props = {
  onClose: () => void;
  chatId: string;
  scrollBottom: () => void;
  generateContentMessage: (data: {
    chatId: string;
    content: string;
    actionType: AIMessageActionType.GenerateContent;
    contentType: ContentType;
  }) => void;
};

const MAX_WORDS = 300;

const SelectContentModal = ({ onClose, chatId, scrollBottom, generateContentMessage }: Props) => {
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SelectContentTypeInputForm>({
    defaultValues: {
      contentType: null,
      additionalInfo: '',
    },
  });

  const additionalInfo = watch('additionalInfo');
  const contentType = useWatch({ control, name: 'contentType' });
  const disabled = !contentType;
  const options = Object.values(ContentType).map(contentType => ({
    label: contentType,
    value: contentType,
  }));

  const filterOptions = (candidate, input) => {
    if (!input) return true;
    const searchTerm = input.toLowerCase();
    return candidate.label.toLowerCase().includes(searchTerm.trim());
  };

  const generateContent = async (data: SelectContentTypeInputForm) => {
    addMessageToChatHistory({
      chatId,
      queryClient,
      message: {
        content: data.additionalInfo,
        messageType: AIMessageType.Human,
        actionType: AIMessageActionType.GenerateContent,
        messageContentType: AIMessageContentType.Text,
        metadata: {
          contentType: data.contentType.value,
        },
      },
    });

    scrollBottom();
    await generateContentMessage({
      chatId,
      content: data.additionalInfo,
      actionType: AIMessageActionType.GenerateContent,
      contentType: data.contentType.value,
    });
    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (countWords(value) > MAX_WORDS) return;
    setValue('additionalInfo', value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const currentContent = watch('additionalInfo');
    const newContent = currentContent + pastedText;
    if (countWords(newContent) > MAX_WORDS) return;
    setValue('additionalInfo', newContent);
  };

  const handleBadgeSelect = (label: string) => {
    const currentValue = watch('additionalInfo') || '';
    const newValue = currentValue ? `${currentValue} ${label}` : label;
    if (countWords(newValue) > MAX_WORDS) return;
    setValue('additionalInfo', newValue);
  };

  return (
    <div className="d-flex flex-column w-100 align-items-end">
      <div className="select-content-modal-wrapper w-100">
        <form
          onSubmit={handleSubmit(generateContent)}
          className="gap-3 p-2 select-content-modal-section d-flex flex-column"
        >
          <div className="gap-1 d-flex flex-column">
            <Controller
              name="contentType"
              control={control}
              rules={{ required: 'Please select a content type first' }}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={false}
                  options={options}
                  classNamePrefix="select"
                  placeholder="Select a relevant content type"
                  backspaceRemovesValue={false}
                  blurInputOnSelect={false}
                  filterOption={filterOptions}
                  isClearable
                  isSearchable
                  components={{
                    Control: ({ children, ...props }: ControlProps) => (
                      <components.Control {...props}>
                        {children}
                        {props.menuIsOpen ? (
                          <ChevronUp
                            size={20}
                            className="text-neutral-900"
                          />
                        ) : (
                          <ChevronDown
                            size={20}
                            className="text-neutral-900"
                          />
                        )}
                      </components.Control>
                    ),
                    Option: (props: OptionProps) => {
                      const library = props.data as any;

                      return (
                        <components.Option {...props}>
                          <div className="d-flex align-items-center font-size-14 fw-normal text-neutral-900">
                            {library.label}
                          </div>
                        </components.Option>
                      );
                    },
                    Input: (props: InputProps) => {
                      return <components.Input {...props} />;
                    },
                  }}
                  maxMenuHeight={200}
                />
              )}
            />
            {errors.contentType && <div className="mt-1 font-size-14 text-error-500">{errors.contentType.message}</div>}
          </div>
          <div className="gap-2 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-size-14 fw-medium text-neutral-900">Additional Information</div>
              <div className="font-size-12 fw-normal text-neutral-500">
                {countWords(additionalInfo)}/{MAX_WORDS} words
              </div>
            </div>
            <div className="d-flex flex-fill align-items-center area-wrapper">
              <Controller
                name="additionalInfo"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className="area-input d-flex align-items-center flex-fill"
                    placeholder="Need further tweaks? Simply ask Michi for assistance...."
                    rows={2}
                    onChange={handleTextareaChange}
                    onPaste={handlePaste}
                    ref={textareaRef}
                  />
                )}
              />
            </div>
          </div>

          <ModalSuggestions
            badges={SELECT_CONTENT_BADGE}
            onBadgeSelect={handleBadgeSelect}
            textareaRef={textareaRef}
          />

          <div className="divider bg-neutral-200"></div>

          <div className="d-flex align-items-center justify-content-between">
            <div
              className="cursor-pointer back-btn font-size-14 fw-medium"
              onClick={onClose}
            >
              Back
            </div>
            <button
              disabled={disabled}
              className="text-white btn btn-primary submit-btn rounded-circle d-flex justify-content-center align-items-center"
              type="submit"
            >
              <ArrowRightIcon
                className="text-white"
                width={16}
                height={16}
                strokeWidth={2}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SelectContentModal;
