import { activateAIAssistant } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useActivateAIAssistant = () => {
  const queryClient = useQueryClient();
  const {
    subscriptionStore: { currentSubscription, updateCurrentSubscription },
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  return useMutation(activateAIAssistant, {
    onSuccess: data => {
      updateCurrentSubscription({ ...currentSubscription, aiAssistantItem: data.aiAssistantItem });
      toast.success('Michi Assistant has been activated');
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
