import React from 'react';
import { Library } from 'types';
import { LibraryContent } from '../LibraryContent';
import useModal from 'hooks/useModal';
import './LibraryListItem.scss';

export const LibraryListItem = ({ library, index }: { library: Library; index?: number }) => {
  const { openModal, closeModal } = useModal();

  const handleNotCreateContent = () => {
    openModal('warning', {
      title: 'Create New Content is not supported on mobile devices',
      content: 'Please switch to desktop to create content',
      hasFooter: false,
      onCancel: () => closeModal(),
    });
  };
  return (
    <>
      <div className="library-list-item d-none d-lg-flex align-items-center px-4 py-3 w-100 bg-white rounded-4">
        <LibraryContent
          library={library}
          index={index}
        />
      </div>

      <div className="d-lg-none d-block w-100 h-100">
        <div
          className="library-list-item px-4 py-3 w-100 bg-white rounded-4"
          onClick={handleNotCreateContent}
        >
          <LibraryContent library={library} />
        </div>
      </div>
    </>
  );
};
