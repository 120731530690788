export const studentReportServiceLines = [
  'Send scores to Learning Management System (LMS).',
  'Question-Based Scoring: Generate scores for each question.*',
  'Resume Feature: Allow learners to continue their progress from where they left off.',
  'Learner List: Access a list of learners who have provided answers for each content item through LTI.',
  'Learner Performance: Review first attempt score, last attempt score, and best attempt score for each learner.',
  'Attempt Details: Explore start time, end time, duration, and total score for each attempt.',
];

export const aiAssistantServiceLines = [
  'Smart Content Generation: Seamlessly create engaging quizzes, assessments, and a variety of other content with the intuitive support of AI.',
  'AI-driven smart document analysis: Utilize AI for extracting captivating content from your documents for your learners.',
  'AI-Powered Question Generation: Generate thought-provoking questions with the help of AI.',
  'Copy and Paste feature with AI: Experience faster and more efficient content creation with AI-enhanced copy and paste functionality.',
];
