import React from 'react';
import './BottomActions.scss';
import classNames from 'classnames';
import { Loading } from 'components/Loading';
interface BottomActionsProps {
  children?: JSX.Element;
  isWelcomeSection?: boolean;
  renderHelpTips?: () => JSX.Element;
  loading?: boolean;
}

const BottomActions: React.FC<BottomActionsProps> = ({
  children,
  isWelcomeSection = true,
  renderHelpTips,
  loading,
}) => {
  return (
    <div className={classNames('bg-white sticky-bottom', { 'pb-0': isWelcomeSection, 'pb-4': !isWelcomeSection })}>
      {renderHelpTips ? <>{renderHelpTips()}</> : null}
      <div className="bg-white">
        <div className="bottom-actions">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-auto">
              <div>
                <Loading color="primary" />
                <div className="mt-2 loading-text">Loading...</div>
              </div>
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomActions;
