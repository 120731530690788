import { reactivateAIAssistant } from 'apis/Payment/Subscription';
import { useAppStore } from 'hooks/useAppStore';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { fetchServerError } from 'utils';

export const useReactivateAIAssistant = () => {
  const queryClient = useQueryClient();
  const {
    subscriptionStore: { currentSubscription, updateCurrentSubscription },
    workspaceStore: { currentWorkspaceId },
  } = useAppStore();

  return useMutation(reactivateAIAssistant, {
    onSuccess: () => {
      updateCurrentSubscription({ ...currentSubscription, cancelAIAssistantAtPeriodEnd: false });
      toast.success('Michi Assistant has been reactivated');
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'usage-record']);
    },
    onError: (error: ErrorResponse) => {
      toast(fetchServerError(error), { type: 'error' });
    },
  });
};
