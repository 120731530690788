import React, { useEffect } from 'react';
import { TextAreaField } from 'components/AppForm/TextAreaField';
import { Controller, useForm } from 'react-hook-form';
import { Check, X } from 'react-feather';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactSVG } from 'react-svg';
import ChatIcon from 'assets/images/ai-assistant/chat.svg';

import './EditNameForm.scss';

type EditNameFormProps = {
  value: string;
  onCancel: () => void;
  onSubmit: (data: { name: string }) => void;
};

const schema = yup.object().shape({
  name: yup.string().required('Chat name is required'),
});

const EditNameForm = ({ value, onCancel, onSubmit }: EditNameFormProps) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    reset({ name: value });
  }, [value, reset]);

  const handleClickCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <form
      className="w-100 edit-name-form"
      onClick={e => e.stopPropagation()}
    >
      <div className="gap-4 flex-fill form-group d-flex align-items-center justify-content-between">
        <ReactSVG src={ChatIcon} />
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextAreaField
              rows={2}
              className="edit-name-form__textarea"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <div className="gap-3 d-flex align-items-center">
          <Check
            className="flex-shrink-0 cursor-pointer text-success"
            width={20}
            height={20}
            onClick={e => {
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
          />

          <X
            className="flex-shrink-0 cursor-pointer text-danger"
            width={20}
            height={20}
            onClick={handleClickCancel}
          />
        </div>
      </div>
      <div className="flex-fill form-group">
        <div className="edit-name-form__feedback-invalid">{errors?.name?.message}</div>
      </div>
    </form>
  );
};

export default EditNameForm;
