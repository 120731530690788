import React from 'react';
import { LibraryCardContent } from '../LibraryCardContent/LibraryCardContent';
import './LibraryCard.scss';
import { Library } from 'types';
import useModal from 'hooks/useModal';

type Props = {
  library: Library;
  index?: number;
};

export const ID_MODAL_NOT_ADD_CONTENT = 'not-add-content';

export const LibraryCard = ({ library, index }: Props) => {
  const { openModal, closeModal } = useModal();

  const handleNotCreateContent = () => {
    openModal('warning', {
      title: 'Create New Content is not supported on mobile devices',
      content: 'Please switch to desktop to create content',
      hasFooter: false,
      onCancel: () => closeModal(),
    });
  };

  return (
    <>
      <div className="bg-white h-100 w-100 rounded-4 d-none d-sm-block library-card">
        <LibraryCardContent
          library={library}
          index={index}
        />
      </div>

      <div
        className="bg-white h-100 w-100 rounded-3 library-card d-block d-sm-none"
        onClick={handleNotCreateContent}
      >
        <LibraryCardContent
          library={library}
          handleNotCreateContent={handleNotCreateContent}
        />
      </div>
    </>
  );
};
