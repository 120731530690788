import React, { useEffect, useRef } from 'react';
import './ChatMessages.scss';
import happyFace from 'assets/images/ai-assistant/face-happy.svg';
import { Copy, RefreshCcw } from 'react-feather';
import { AIMessageActionType, AIMessageContentType, AIMessage, AIMessageType } from 'types';
import UserTextPrompt from '../UserTextPrompt/UserTextPrompt';
import Markdown from 'react-markdown';
import AIMascot from '../AIMascot/AIMascot';
import { ChatInputLoading } from '../ChatInputLoading';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

interface Props {
  chatId: string;
  messages: AIMessage[];
  isChatAnalyzed: boolean;
  isNewMessageLoading: boolean;
  isAnalyzing: boolean;
  isRefineLoading: boolean;
  isSelectContentLoading: boolean;
  regenerateMessage: ({ chatId, messageId }: { chatId: string; messageId: string }) => void;
  isRegenerateLoading: boolean;
}

const ChatMessages = ({
  chatId,
  messages,
  isChatAnalyzed,
  isNewMessageLoading,
  isAnalyzing,
  isRefineLoading,
  isSelectContentLoading,
  regenerateMessage,
  isRegenerateLoading,
}: Props) => {
  const bottomElementRef = useRef<HTMLDivElement>();
  const centerElementRef = useRef<HTMLDivElement>();

  const isLoadingChatInput =
    isAnalyzing || isRegenerateLoading || isNewMessageLoading || isRefineLoading || isSelectContentLoading;

  const titleLabel = (actionType: AIMessageActionType) => {
    if (actionType === AIMessageActionType.GenerateContent || actionType === AIMessageActionType.RefineContent)
      return 'Review Content';

    return 'Review Context';
  };

  const descriptionLabel = (actionType: AIMessageActionType) => {
    switch (actionType) {
      case AIMessageActionType.GenerateContent:
      case AIMessageActionType.RefineContent:
        return 'Please review the text version of your content below and select <strong>Apply & Create</strong> to create content.';

      case AIMessageActionType.RefineContext:
        return 'Please review the text version of your content below and select a relevant content type from our list.';

      default:
        return "Please review your content's context and refine if needed.";
    }
  };

  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    toast.success('Content copied');
  };

  const handleRegenerateMessage = (messageId: string) => {
    regenerateMessage({ chatId, messageId });
    scrollToBottom();
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomElementRef.current?.scrollIntoView({ block: 'end' });
    }, 100);
  };

  const scrollToCenter = () => {
    setTimeout(() => {
      centerElementRef.current?.scrollIntoView({ block: 'center' });
    }, 100);
  };

  useEffect(() => {
    scrollToCenter();
  }, [messages.filter(message => message.messageType === AIMessageType.AI).length]);

  useEffect(() => {
    scrollToBottom();
  }, [chatId]);

  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className="chat-list"
        >
          {message.messageType === AIMessageType.AI ? (
            <>
              <div ref={centerElementRef}></div>
              <div className="py-1 d-flex align-items-center">
                <AIMascot
                  faceSrc={happyFace}
                  className="ai-mascot ai-mascot-small"
                  isFloat={false}
                />
                <div className="font-size-12 fw-semibold text-primary-400 ms-2">Michi Assistant</div>
              </div>
              <div className="gap-3 p-2 bg-white ai-response d-flex flex-column">
                {isChatAnalyzed && (
                  <div className="font-size-14">
                    <div className="mb-1 fw-medium text-neutral-900">{titleLabel(message.actionType)}</div>
                    <div
                      className="fw-normal text-neutral-500"
                      dangerouslySetInnerHTML={{ __html: descriptionLabel(message.actionType) }}
                    />
                  </div>
                )}
                <Markdown className="custom-markdown">{message.content}</Markdown>

                <div className="gap-2 p-2 d-flex align-items-center">
                  {index === messages.length - 1 &&
                    message.messageType === AIMessageType.AI &&
                    (isChatAnalyzed || message.messageContentType === AIMessageContentType.Content) && (
                      <div className="gap-3 d-flex align-items-center">
                        {message.messageContentType === AIMessageContentType.Content && (
                          <div className="gap-1 cursor-pointer d-flex align-items-center text-neutral-400">
                            <div
                              className="action-icon d-flex align-items-center"
                              onClick={() => handleRegenerateMessage(message.id)}
                            >
                              <RefreshCcw
                                size={16}
                                id="regenerate"
                              />
                              <UncontrolledTooltip
                                target="regenerate"
                                placement="top"
                              >
                                Regenerate
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  <div
                    className="cursor-pointer action-icon d-flex align-items-center"
                    onClick={() => handleCopy(message.content)}
                  >
                    <Copy
                      size={16}
                      className="text-neutral-400"
                      id="copy"
                    />
                    <UncontrolledTooltip
                      target="copy"
                      placement="top"
                    >
                      Copy content
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="human-chat">
              <UserTextPrompt message={message} />
            </div>
          )}
        </div>
      ))}

      {isLoadingChatInput && <ChatInputLoading />}
      <div ref={bottomElementRef}></div>
    </>
  );
};

export default ChatMessages;
