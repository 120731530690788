import React from 'react';
import { LibraryCard } from './LibraryCard';
import { Library } from 'types';
import { useAppStore } from 'hooks/useAppStore';
import classNames from 'classnames';

type Props = {
  libraries: Library[];
};

export const LibraryGallery = ({ libraries }: Props) => {
  const { ltiMode } = useAppStore();

  return (
    <div
      className="row g-4 h-100"
      data-cy="library-gallery"
    >
      {libraries.map((library, index) => (
        <div
          className={classNames('px-2 px-lg-3 col-6 col-lg-3 col-3xl-2', {
            'col-md-4': !ltiMode,
            'col-sm-4': ltiMode,
          })}
          key={index}
          data-cy="library-card"
        >
          <LibraryCard
            library={library}
            index={index}
          />
        </div>
      ))}
    </div>
  );
};
