import React from 'react';
import './LibraryCardContent.scss';
import { Library } from 'types';
import { UncontrolledTooltip } from 'reactstrap';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { useAppStore } from 'hooks/useAppStore';
import { useMediaScreen } from 'hooks/useMediaScreen';

interface Props {
  library: Library;
  index?: number;
  handleNotCreateContent?: () => void;
}

export const LibraryCardContent: React.FC<Props> = ({ library, index, handleNotCreateContent }) => {
  const { closeModal } = useModal();
  const {
    ltiStore: { workspaceId },
    ltiMode,
  } = useAppStore();
  const navigate = useNavigate();
  const { folderId = '', id: contentId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { state } = location;
  const { isSmDown } = useMediaScreen();

  const addNew = () => {
    if (isSmDown) {
      handleNotCreateContent();
      return;
    }

    closeModal();

    const navigateFrom = state?.from ? state?.from : location.pathname;
    const shouldRefresh = !!contentId || searchParams.get('library')?.includes(library.metadata.machineName);

    const url = ltiMode ? `/${workspaceId}/lti/launch` : '';
    navigate(
      `${url}/contents/new?library=${library.metadata.machineName} ${library.metadata.majorVersion}.${library.metadata.minorVersion}&folderId=${folderId}`,
      {
        replace: true,
        state: {
          shouldRefresh,
          from: navigateFrom,
        },
      },
    );
  };

  const readMore = () => {
    console.log('readMore');
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div
        className="gap-4 mb-auto d-flex flex-column library-card__type"
        data-cy="library-card-type"
      >
        <div className="d-flex align-items-center justify-content-center">
          <img
            className="library-card__type__img "
            src={library?.image}
            data-cy="library-card__type__img"
          />
        </div>

        <div className="library-card__type__content">
          <div
            className="mb-1 fw-bold library-card__type__content__title font-size-14"
            data-cy="library-card__type__content__title"
          >
            {library?.title}
          </div>
          <div
            className="library-card__type__content__description font-size-12"
            data-cy="library-card__type__content__description"
            id={'library-card-' + index}
          >
            {library?.description}

            <UncontrolledTooltip
              className="d-none d-sm-block"
              container="body"
              placement="bottom"
              target={'library-card-' + index}
            >
              {library?.description}
            </UncontrolledTooltip>
          </div>
        </div>
      </div>
      <div
        className="flex-wrap gap-2 d-flex justify-content-end library-card__btn"
        data-cy="library-card__btn"
      >
        <button
          type="button"
          className="btn btn-outline-primary library-card__btn__read-more flex-fill text-nowrap d-none"
          data-cy="library-card__btn__read-more"
          onClick={readMore}
        >
          Read More
        </button>

        <button
          type="button"
          className="btn btn-outline-primary d-block library-card__btn__add-new flex-fill text-nowrap font-size-14 fw-medium"
          data-cy="library-card__btn__add-new"
          onClick={addNew}
        >
          Add New
        </button>
      </div>
    </div>
  );
};
