import React from 'react';
import { TitleForm } from '../TitleForm/TitleForm';
import { useCreateFolder } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { useQueryClient } from 'react-query';
import './CreateFolder.scss';

export type CreateFolderModalProps = {
  onCancel: () => void;
  defaultValue?: string;
  labelOK: string;
  inputLabel?: string;
  inputPlaceHolder?: string;
  headerTitle: string;
  parentId?: string;
};
export const CreateFolder = observer((props: CreateFolderModalProps) => {
  const { onCancel, parentId } = props;
  const { mutateAsync, isLoading } = useCreateFolder();
  const {
    folderStore: { modalFolderKey },
  } = useAppStore();

  const queryClient = useQueryClient();

  const submit = async ({ title }: { title: string }) => {
    await mutateAsync(
      { title, parentId: parentId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(modalFolderKey);
        },
      },
    );
    onCancel?.();
  };

  return (
    <TitleForm
      {...props}
      onOk={submit}
      isLoading={isLoading}
    />
  );
});
