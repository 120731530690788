import React, { useState } from 'react';
import {
  ChevronRight,
  Copy,
  Edit2,
  Folder,
  Globe,
  Lock,
  MoreVertical,
  Radio,
  Share2,
  Star,
  Trash2,
} from 'react-feather';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import './ContentActionsPopup.scss';
import { ContentActions, ContentStatus, Document, TDocumentType } from 'models/Content';
import useModal from 'hooks/useModal';
import { RenameModalProps } from 'components/ModalManager/Rename/Rename';
import { useAddOrRemoveStarContent, useDuplicate, useChangeStatus, useMoveToTrash } from 'hooks';
import { ContentPermission } from 'components';
import { EditPublishedContentPermission } from 'components/AIOffset/components/EditPublishedContentPermission';

type IProps = {
  content: Document;
};

const ContentActionsPopup = (props: IProps) => {
  const { content } = props;
  const [isStatusOpen, toggleStatusOpen] = useState(false);
  const { openModal, closeModal } = useModal();

  const statusOptions: ContentActions[] = [
    {
      icon: (
        <Globe
          size={20}
          className="gray-600"
        />
      ),
      label: 'Published',
      action: 'publish',
    },
    {
      icon: (
        <Lock
          size={20}
          className="gray-600"
        />
      ),
      label: 'Inactive',
      action: 'deactivate',
    },
  ];

  const { mutate: addOrRemoveStarContent } = useAddOrRemoveStarContent();
  const { mutate: moveToTrash } = useMoveToTrash();
  const { mutate: changeStatus } = useChangeStatus(content);
  const { mutateAsync: duplicate } = useDuplicate();

  const confirmAction = (action: string) => {
    openModal('warning', {
      title: `Are you sure you want to ${action} this content?`,
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      titleIcon: (
        <Radio
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: () => {
        changeStatus({
          id: content.id,
          status: action === 'publish' ? ContentStatus.Published : ContentStatus.Inactive,
        });

        closeModal();
      },
      onCancel: () => closeModal(),
    });
  };

  const duplicateAction = (contentId: string) => {
    openModal('duplicate-content', {
      title: `Do you want to duplicate this content?`,
      labelOK: 'Yes',
      labelCancel: 'Cancel',
      titleIcon: (
        <Copy
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: async () => {
        await duplicate(contentId);
        closeModal();
      },
      onCancel: () => closeModal(),
    });
  };

  const openMoveFolderModal = () => {
    openModal('move-folder', {
      onAccept: () => closeModal(),
      onCancel: () => closeModal(),
      content,
    });
  };
  const shareWithAction = () => {
    openModal('share-with', {
      title: content.title,
      isShareMultipleFile: false,
      contentId: content.id,
      owner: content.user,
      onCancel: () => closeModal(),
      onOK: () => closeModal(),
    });
  };
  const onRenameContent = () => {
    openModal('rename-content', {
      defaultValue: content.title,
      currentId: content.id,
      onCancel: () => closeModal(),
      onOk: () => closeModal(),
    } as RenameModalProps);
  };

  const openModalMoveFolderToTrash = () => {
    openModal('warning', {
      title: 'Are you sure you want to move this folder to Trash?',
      content: 'All items contained in this folder will be also moved to Trash.',
      labelOK: "Yes, I'm sure",
      labelCancel: 'No, cancel',
      titleIcon: (
        <Trash2
          strokeWidth={1.5}
          className="text-primary block"
          size={24}
        />
      ),
      onAccept: () => {
        moveToTrash(content.id);
        closeModal();
      },
      onCancel: () => closeModal(),
    });
  };

  const onStarredContent = () => {
    addOrRemoveStarContent(content);
  };

  const onMoveToTrash = () => {
    content.kind === 'Folder' ? openModalMoveFolderToTrash() : moveToTrash(content.id);
  };

  return (
    <UncontrolledDropdown
      key={content.id}
      direction="start"
      className="pointer"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <DropdownToggle tag="span">
        <MoreVertical
          size={24}
          className="gray-500"
        />
      </DropdownToggle>
      <DropdownMenu className="end-0 content-actions-dropdown">
        <ContentPermission
          role={content.contentRole}
          action="content.duplicate"
        >
          {content.kind === TDocumentType.Content && (
            <EditPublishedContentPermission content={content}>
              <DropdownItem
                data-cy="duplicate-content"
                className="d-none"
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => duplicateAction(content.id)}
                >
                  <Copy
                    size={20}
                    className="gray-600"
                  />
                  <span className="ms-2">Duplicate</span>
                </div>
              </DropdownItem>
            </EditPublishedContentPermission>
          )}
        </ContentPermission>

        <ContentPermission
          role={content.contentRole}
          action="content.update"
        >
          <EditPublishedContentPermission content={content}>
            <DropdownItem
              data-cy="rename-content"
              onClick={onRenameContent}
            >
              <div className="d-flex align-items-center">
                <Edit2
                  size={20}
                  className="gray-600"
                />
                <span className="ms-2">Rename</span>
              </div>
            </DropdownItem>
          </EditPublishedContentPermission>
        </ContentPermission>

        <ContentPermission
          role={content.contentRole}
          action="content.addStar"
        >
          <DropdownItem
            data-cy="bookmark-content"
            onClick={onStarredContent}
          >
            <div className="d-flex align-items-center">
              <Star
                size={20}
                className="gray-600"
              />
              <span className="ms-2">{content?.starred ? 'Remove star' : 'Add star'}</span>
            </div>
          </DropdownItem>
        </ContentPermission>

        <ContentPermission
          role={content.contentRole}
          action="content.moveToFolder"
        >
          <>
            <DropdownItem
              divider
              className="my-0"
            />
            <DropdownItem
              data-cy="move-content-to-folder"
              onClick={() => openMoveFolderModal()}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Folder
                    size={20}
                    className="gray-600 me-0"
                  />
                  <span className="ms-2">Move to folder</span>
                </div>
                <ChevronRight
                  size={20}
                  className="gray-600 me-0"
                />
              </div>
            </DropdownItem>
          </>
        </ContentPermission>
        <ContentPermission
          role={content.contentRole}
          action="content.updatePermissions"
        >
          <DropdownItem
            data-cy="share-content"
            onClick={shareWithAction}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Share2
                  size={20}
                  className="gray-600"
                />
                <span className="ms-2">Share with</span>
              </div>

              <ChevronRight
                size={20}
                className="gray-600 me-0"
              />
            </div>
          </DropdownItem>
        </ContentPermission>

        {content.kind === TDocumentType.Content && (
          <ContentPermission
            role={content.contentRole}
            action="content.update"
          >
            <EditPublishedContentPermission content={content}>
              <DropdownItem
                data-cy="change-content-status"
                onMouseOver={() => toggleStatusOpen(true)}
                onMouseLeave={() => toggleStatusOpen(false)}
              >
                <Dropdown
                  direction="start"
                  className="w-100"
                  isOpen={isStatusOpen}
                  toggle={() => toggleStatusOpen(!isStatusOpen)}
                >
                  <DropdownToggle tag="span">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <Radio
                          size={20}
                          className="gray-600"
                        />
                        <span className="ms-2">Change status</span>
                      </div>

                      <ChevronRight
                        size={20}
                        className="gray-600 me-0"
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    className="start-0 content-actions-dropdown content-actions__sub-menu status-menu"
                    onMouseLeave={() => toggleStatusOpen(false)}
                  >
                    {statusOptions.map((item, key) => {
                      const isDisable = () =>
                        (content.status === ContentStatus.Draft && item.action === 'deactivate') ||
                        (content.status === ContentStatus.Published && item.action === 'publish') ||
                        (content.status === ContentStatus.Inactive && item.action === 'deactivate');

                      return (
                        <DropdownItem
                          key={key}
                          disabled={isDisable()}
                          onClick={!isDisable() ? () => confirmAction(item.action) : null}
                        >
                          <div className={`d-flex align-items-center ${isDisable() ? 'disabled' : ''}`}>
                            <span className="icon">{item.icon}</span>
                            <span className="ms-2 label">{item.label}</span>
                          </div>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </DropdownItem>
            </EditPublishedContentPermission>
          </ContentPermission>
        )}
        <ContentPermission
          role={content.contentRole}
          action="content.moveToTrash"
        >
          <EditPublishedContentPermission content={content}>
            <>
              <DropdownItem
                divider
                className="my-0"
              />
              <DropdownItem
                data-cy="remove-content"
                onClick={onMoveToTrash}
              >
                <div className="d-flex align-items-center">
                  <Trash2
                    size={20}
                    className="gray-600"
                  />
                  <span className="ms-2">Move to trash</span>
                </div>
              </DropdownItem>
            </>
          </EditPublishedContentPermission>
        </ContentPermission>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ContentActionsPopup;
