import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { MenuItem } from '../MenuItem';
import { NewContentBtn } from '../NewContentBtn';
import { SidebarMenuBottom } from '../SidebarMenuBottom';
import './SidebarMenu.scss';
import { useGetSidebarMenu } from 'common/data/ts/sidebar';
import { UserPermission } from 'components/UserPermission';
import EvokioLogo from 'assets/icons/EvokioLogo';

export const SidebarMenu = observer(() => {
  const sidebarMenu = useGetSidebarMenu();

  return (
    <div
      className="sidebar-menu"
      data-cy="sidebar-menu"
    >
      <div
        data-simplebar="true"
        className="h-100"
      >
        <div
          data-simplebar="init"
          className="h-100"
        >
          <div className="simplebar-wrapper m-0">
            <div className="simplebar-mask">
              <div className="simplebar-offset">
                <div className="simplebar-content-wrapper mm-show">
                  <div className="simplebar-content mm-show mm-active">
                    <div className="navbar-brand-box border-bottom border-gray-100 py-3 sticky-top bg-white">
                      <UserPermission
                        actions={['content.create']}
                        renderHasNotPermission={
                          <Link
                            to="/profile/edit"
                            className="d-block m-auto"
                          >
                            <EvokioLogo />
                          </Link>
                        }
                      >
                        <Link
                          to="/dashboard"
                          className="d-block m-auto"
                        >
                          <EvokioLogo />
                        </Link>
                      </UserPermission>
                    </div>

                    <UserPermission actions={['content.create']}>
                      <div className="px-2 pt-3">
                        <NewContentBtn />
                      </div>
                    </UserPermission>

                    <div
                      id="sidebar-menu"
                      className="mm-active"
                      data-cy="sidebar-menu"
                    >
                      <ul
                        className="metismenu list-unstyled mm-show mm-active"
                        id="side-menu"
                      >
                        {(sidebarMenu || [])
                          .filter(item => !item.hide)
                          .map(({ link, icon, title, subMenu, actions }) => {
                            return (
                              <UserPermission
                                key={title}
                                actions={actions}
                              >
                                <MenuItem
                                  to={link}
                                  title={title}
                                  subMenu={subMenu}
                                  icon={icon}
                                ></MenuItem>
                              </UserPermission>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="simplebar-placeholder">
              <SidebarMenuBottom />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
